<template>
  <div>
    <!-- info and auth bar -->
    <div class="flex flex-row items-center py-2 px-4 border-b select-none">
      <button :class="$route.path !== '/dashboard' || 'invisible'" class="btn btn-link"
              @click="$router.push('/dashboard')">
        Zum Dashboard
      </button>
      <button :class="backName || 'invisible'" class="btn btn-link" @click="$router.push(backLink)">
        Zurück zum {{ backName }}
      </button>

      <span class="ml-auto text-center mr-auto font-bold px-2">
      {{
          title || $store.state.organizations && $store.state.organizations.length ? getPrimaryOrganization.dashboardTitle + " Inhaltsverwaltung" : "Quiz App"
        }}

        <!-- no logo set error message -->
      <span class="text-red-500" v-if="!$store.state.settings.logo">
        – Durchführung der Quizze noch nicht möglich, dafür bitte in den Einstellungen das Logo der Fachstelle konfigurieren.
      </span>
    </span>

      <span v-if="$store.state.user" class="">Angemeldet als {{ $store.state.user.displayName }}</span>
      <button class="btn btn-link" @click="auth.signOut() && $store.commit('addMessage', 'Erfolgreich abgemeldet.')">
        Abmelden
      </button>
      <a class="btn btn-link" href="/caritas-suchtgeschichten-quiz-anleitung-v20240204.pdf" target="_blank">
        Anleitung öffnen
        <font-awesome-icon icon="external-link-alt"/>
      </a>
    </div>
  </div>
</template>
<script>
import { auth } from "../plugins/firebase";
import { getPrimaryOrganization } from "../constants/questions";

export default {
  props: ["title", "backName", "backLink"],
  data() {
    return {
      auth
    }
  },
  computed: { getPrimaryOrganization }
}
</script>